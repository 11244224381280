import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"


import LinkedIn from "./externalServiceScripts/LinkedIn"
import LandBot from "./externalServiceScripts/LandBot"
import HubSpot from "./externalServiceScripts/HubSpot"
import GoogleAnalytics from "./externalServiceScripts/GoogleAnalytics"

const activeWindow = typeof window !== "undefined" ? window : null

function Header({ description, lang, meta, title, canonicalURL }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >

      { canonicalURL && activeWindow && <link rel="canonical" href={`${activeWindow.location.pathname}`} /> }

      {/* Load External Scripts - must be functions, will not load as components */}
        { LinkedIn() }
        { LandBot() }
        { HubSpot() }
        { GoogleAnalytics() }


    </Helmet>
  )
}


Header.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Header.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Header
